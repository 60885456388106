/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
/* eslint-disable no-undef */
var location = window.location;
var goToAPIReferenceElement = function() {
  let locationURL = new URL(window.location);
  let anchorId = locationURL.searchParams.get("anchorId");
  setTimeout(() => {
    if (anchorId && anchorId.length < 100) {
      var element = document.getElementById(anchorId);
    }
    if (element) {
      var topOfElement = element.offsetTop - 130;
      window.scroll({ top: topOfElement });
    }
  }, 200);
};
window.goToAPIReferenceElement = goToAPIReferenceElement;

function getElementsByXPath(xpath, parent) {
  let results = [];
  let query = document.evaluate(
    xpath,
    parent || document,
    null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
    null
  );
  for (let i = 0, length = query.snapshotLength; i < length; ++i) {
    results.push(query.snapshotItem(i));
  }
  return results;
}

function markAndScroll() {
  let locationURL = new URL(window.location);
  let searchQuery = locationURL.searchParams.get("searchQuery");
  // Logic to mark words
  if (searchQuery) {
    if (searchQuery.length > 100) {
      searchQuery = searchQuery.substring(0, 100);
    }
    let searchQueryArray = searchQuery.split(",");
    xpathPartial = ``;
    for (
      let queryWordIndex = 0;
      queryWordIndex < searchQueryArray.length;
      queryWordIndex++
    ) {
      if (queryWordIndex !== searchQueryArray.length - 1) {
        xpathPartial +=
          `contains(text(),"` + searchQueryArray[queryWordIndex] + `") or `;
      } else {
        xpathPartial +=
          `contains(text(),"` + searchQueryArray[queryWordIndex] + `")`;
      }
    }
    let xpathQuery =
      `//*[not(*) and not(contains(@class, "sidebar-item")) and  not(self::code) and not(self::pre) and (` +
      xpathPartial +
      `)]`;
    let resultsArray = getElementsByXPath(xpathQuery);
    let firstMatch = resultsArray[0];
    if (resultsArray.length > 0) {
      for (
        let resultIndex = 0;
        resultIndex < resultsArray.length;
        resultIndex++
      ) {
        for (
          let queryWordIndex = 0;
          queryWordIndex < searchQueryArray.length;
          queryWordIndex++
        ) {
          if (
            resultsArray[resultIndex].innerText.includes(
              searchQueryArray[queryWordIndex]
            )
          ) {
            resultsArray[resultIndex].innerHTML = resultsArray[
              resultIndex
            ].innerHTML.replace(
              searchQueryArray[queryWordIndex],
              "<mark>" + searchQueryArray[queryWordIndex] + "</mark>"
            );
          }
        }
      }
      setTimeout(() => {
        var topOfElement = firstMatch.getBoundingClientRect().top - 170;
        window.scroll({ top: topOfElement, behavior: "smooth" });
      }, 0);
    }
  }
}

// ^ TODO: Overwrite native scrolling
SwaggerUI = require("swagger-ui");
window.SwaggerUI = SwaggerUI;
exports.shouldUpdateScroll = args => {
  markAndScroll();
  if (location.hash && document.querySelector(`${location.hash}`) != null) {
    setTimeout(() => {
      var topOfElement =
        document.querySelector(`${location.hash}`).offsetTop - 260;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }, 0);
  }
};
